import "./Footer.css"
import {Link} from "react-router-dom";
import FlexBox from "../flexbox/FlexBox";

const GoPay = require("../../asset/icons/gopay.svg").ReactComponent;
const CeskoPlatiKartou = require("../../asset/icons/ceskoplatikartou.svg").ReactComponent;


export default function Footer() {
    return <div className={"footer-container"}>
        <footer>
            <ul className={"footer-col col2"}>
                <li className={"bold"}>Podpora</li>
                <li>
                    Potřebuješ s něcím pomoct? Dej nám vědět na <a className={"link-color"}
                                                                   href={"https://discord.ptero-cloud.net"}
                                                                   target={"_blank"} rel={"noreferrer"}>Discord</a>
                    {/*nebo si <Link className={"link-color"} to={"/tickets/new"}>vytvoř ticket</Link>*/}
                </li>
                <li className={"bold"}>Obchod</li>
                <li>Email: platby@ptero-cloud.net</li>
                <li>IČO: 17348056</li>
                <li><Link to={"/vop"}>Všeobecné obchodní podmínky</Link></li>
                <FlexBox justifyContent={"space-between"} gap={"30%"}>
                    <GoPay/>
                    <CeskoPlatiKartou/>
                </FlexBox>
            </ul>
            <ul className={"footer-col col3"}>
                <li className={"bold"}>Sociální sítě</li>
                <li><a href={"https://youtube.ptero-cloud.net"}>YouTube</a></li>
                <li><a href={"https://instagram.ptero-cloud.net"}>Instagram</a></li>
                <li><a href={"https://tiktok.ptero-cloud.net"}>TikTok</a></li>
                <li><a href={"https://minecraft-servery.cz"}>Minecraft servery</a></li>
            </ul>
            <ul className={"footer-col col3"}>
                <li className={"bold"}>Pravidla</li>
                <li><Link to={"/pravidla"}>Pravidla v češtině</Link></li>
                <li><Link to={"/rules"}>Rules in English</Link></li>
                <li><Link to={"/gdpr"}>Zásady o ochraně osobních údajů</Link></li>
            </ul>
        </footer>
        <div className={"copyright"}>
            <p>© Ptero-Cloud s.r.o. {new Date().getFullYear()}</p>
        </div>
    </div>
}