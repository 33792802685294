import "./HomeTitle.css"
import {Link} from "react-router-dom";
import Button from "../../../component/button/Button";
import ViewTitle from "../../../component/title/view/ViewTitle";
import FlexBox from "../../../component/flexbox/FlexBox";
import Title from "../../../component/title/Title";
import {useState} from "react";

export default function HomeTitle() {
    const [state, setState] = useState<boolean>(false);

    return <ViewTitle>
        <FlexBox justifyContent={"space-between"} alignItems={"center"} gap={20}>
            <div className={"content"}>
                <Title main={true}>Ptero-Cloud</Title>
                <p className={"description"}>
                    Vítej na našich oficiálních webových stránkách. Nezapomeň se připojit k našemu Discordu a podívat se
                    na další sociální sítě. Těšíme se, až tě uvidíme!
                </p>
                <p
                    className="server-ip"
                    onClick={() => {
                        navigator.clipboard.writeText("mc.ptero-cloud.net")
                            .then(() => {
                                setState(true);
                                setTimeout(() => {
                                    setState(false);
                                }, 2000);
                            })
                            .catch(err => console.error("Failed to copy:", err));
                    }}
                >
                    {state ? null : (<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                          className="icon icon-tabler icons-tabler-outline icon-tabler-copy">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path
                            d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"/>
                        <path
                            d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"/>
                    </svg>)}
                    <span>{state ? "Zkopírováno!" : "mc.ptero-cloud.net"}</span>
                </p>
                <a href={"https://discord.ptero-cloud.net"} target="_blank" rel="noreferrer">
                    <Button>Připoj se na Discord</Button>
                </a>
                <Link to={"/store"}><Button type="secondary">Obchod</Button></Link>
            </div>
            <img src="/img/logo/large.webp" className="logo" alt={""}/>
        </FlexBox>
    </ViewTitle>
}